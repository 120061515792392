import React from 'react';
import { Link } from 'gatsby';
import FormFields from './form-fields';
import styles from './contact-form.module.scss';

export default () => {
  return (
    <div className={`${styles.contactForm}`}>
      <div className="has-text-centered has-text-white content">
        <h3 className="title is-5 has-text-white">Send us a message</h3>
        <p className="content is-size-7">
          Do you have a question or comment? The Trustaff team is here to help.
        </p>
        <p className="content is-size-7">
          Our offices are open Monday-Friday from 8am-6pm. To reach someone
          immediately, please give us a call at <b>877-880-0346</b>.
        </p>
        <p className="content is-size-7">
          If you are a traveler interested in getting started, please{' '}
          <Link className={styles.linkLight} to="/apply/">
            click here.
          </Link>
        </p>
      </div>
      <FormFields />
    </div>
  );
};
