import React, { useState } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';

import contactUsService from '../../services/contact-us-service';
import Input from '../form-components/input';
import Textarea from '../form-components/textarea';
import TelephoneInput from '../form-components/telephone-input';
import ValidationHelper from './validation-helper';

import styles from './form-fields.module.scss';

const FormFields = () => {
  const [isLoading, setIsLoading] = useState(false);

  const validate = ValidationHelper.getValidatorFunc();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
    },
    validate,
    onSubmit: values => {
      setIsLoading(true);
      contactUsService
        .sendStaffNotification(values)
        .then(() => {
          navigate('/contact-us/success-confirmation');
        })
        .catch(() => {
          setIsLoading(false);
          formik.setStatus({ apiError: true });
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="field">
        <Input
          id="firstName"
          name="firstName"
          placeholder="First name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.firstName}
          errorMessage={
            formik.touched.firstName && formik.errors.firstName
              ? formik.errors.firstName
              : null
          }
          errorClass={styles.lightErrorMsg}
          className="is-rounded"
        />
      </div>

      <div className="field">
        <Input
          id="lastName"
          name="lastName"
          placeholder="Last name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.lastName}
          errorMessage={
            formik.touched.lastName && formik.errors.lastName
              ? formik.errors.lastName
              : null
          }
          errorClass={styles.lightErrorMsg}
          className="is-rounded"
        />
      </div>

      <div className="field">
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          errorMessage={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
          errorClass={styles.lightErrorMsg}
          className="is-rounded"
        />
      </div>

      <div className="field">
        <TelephoneInput
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Phone number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
          errorMessage={
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? formik.errors.phoneNumber
              : null
          }
          errorClass={styles.lightErrorMsg}
          className="is-rounded"
        />
      </div>

      <div className="field">
        <Textarea
          id="message"
          name="message"
          placeholder="What can we help with?"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.message}
          errorMessage={
            formik.touched.message && formik.errors.message
              ? formik.errors.message
              : null
          }
          errorClass={styles.lightErrorMsg}
          className="is-rounded"
        />
      </div>

      {formik.status && formik.status.apiError && (
        <p className="notification is-danger">
          There was a problem saving your data. Please try again.
        </p>
      )}

      <div className={`has-text-centered field ${styles.submitButtonWrapper}`}>
        <button
          type="submit"
          className={`button ${styles.submitButton} is-primary ${
            isLoading ? 'is-loading disabled' : ''
          }
                `}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default FormFields;
