import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../../components/layout';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import { GatsbyImg } from '../../services/img-location/img-location-service';
import LocationsSection from '../../components/contact-us/locations-section';
import ContactForm from '../../components/contact-us/contact-form';

import styles from './index.module.scss';

export default ({ data }) => {
  const gatsbyOgImg = new GatsbyImg('contact-us-og', data);
  const gatsbyHeroImg = new GatsbyImg('contact-us-hero', data);

  return (
    <>
      <Helmet>
        <title>Contact us - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="We're here to answer any questions about the staffing process, from resume advice and career planning to talent recruitment, industry trends, and more. Contact us in the way that's most convenient for you."
        />
        <meta property="og:title" content="Reach out &amp; make a connection" />
        <meta
          property="og:description"
          content="We're here to answer any questions about the staffing process, from resume advice and career planning to talent recruitment, industry trends, and more. Contact us in the way that's most convenient for you."
        />
        <meta property="og:image" content={gatsbyOgImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/contact-us/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImageMini fluid={gatsbyHeroImg.fluidImg} hasOverlay>
          <h1 className={`title is-1 has-text-white ${styles.heroTitle}`}>
            Contact us
          </h1>
        </HeroImageMini>

        <div className="section">
          <div className="columns">
            <div className="column columns is-12 is-10-desktop is-offset-1-desktop">
              <div className="column is-8">
                <div>
                  <h2 className="title is-3">
                    Reach out &amp; make a connection
                  </h2>
                  <p className="content">
                    We're here to answer any questions about the staffing
                    process, from resume advice and career planning to talent
                    recruitment, industry trends, and more. Contact us in the
                    way that's most convenient for you.
                  </p>
                  <h3 className="title is-5">
                    <span className="has-text-info">Call us toll-free:</span>{' '}
                    <br /> <br />
                    <span className="is-size-3 has-text-weight-bold">
                      877.880.0346
                    </span>
                  </h3>
                </div>
                <hr className={styles.sectionDivider} />
                <LocationsSection />
              </div>
              <div className="column is-4">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^contact-us/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
