import React from 'react';

/* eslint-disable jsx-a11y/no-onchange */

const Textarea = ({
  id,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  errorMessage,
  errorClass,
  className,
  showLabel,
}) => {
  const getErrorMessage = errorMessage => {
    if (errorMessage === null) {
      return null;
    }

    return <p className={`help ${errorClass}`}>{errorMessage}</p>;
  };

  return (
    <>
      <label className={`label ${showLabel ? '' : 'is-sr-only'}`} htmlFor={id}>
        {placeholder}
      </label>
      <div className="control">
        <textarea
          id={id}
          name={name}
          className={`textarea ${className}`}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      </div>
      {getErrorMessage(errorMessage)}
    </>
  );
};

export default Textarea;

Textarea.propTypes = {};

Textarea.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  mask: null,
  errorClass: '',
  className: '',
  showLabel: false, // make label screen reader only by default
  id: '',
};
