import React from 'react';

import Input from './input';

const TelephoneInput = props => (
  <Input
    type="tel"
    mask={[
      '(',
      /[2-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    {...props}
  />
);

export default TelephoneInput;
