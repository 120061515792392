import React from 'react';
import MaskedInput from 'react-text-mask';
import { useAppContext } from '../../providers/app-provider';
/* eslint-disable jsx-a11y/no-onchange */

const Input = ({
  id,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  errorMessage,
  errorClass,
  type,
  mask,
  className,
  showLabel,
  shouldCheckForCapsLock,
}) => {
  const { checkForCapsLock } = useAppContext();
  const getErrorMessage = errorMessage => {
    if (errorMessage === null) {
      return null;
    }

    return <p className={`help ${errorClass}`}>{errorMessage}</p>;
  };

  const InputComponent = mask !== null ? MaskedInput : NativeInput;

  return (
    <>
      <label className={`label ${showLabel ? '' : 'is-sr-only'}`} htmlFor={id}>
        {placeholder}
      </label>
      <div className="control">
        <InputComponent
          id={id}
          name={name}
          type={type}
          className={`input ${className}`}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          mask={mask}
          onKeyDown={shouldCheckForCapsLock ? checkForCapsLock : null}
        />
      </div>
      {getErrorMessage(errorMessage)}
    </>
  );
};

// NativeInput is just a wrapper for React's native <input /> component
// wrapping it in a NativeInput class allows us dynamically set the <InputComponent />
const NativeInput = props => {
  return <input {...props} />;
};

export default Input;

Input.propTypes = {};

Input.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  type: 'text',
  mask: null,
  errorClass: '',
  className: '',
  showLabel: false, // make label screen reader only by default
  id: '',
  shouldCheckForCapsLock: false,
};
