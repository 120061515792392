import {
  isValidEmail,
  isValidName,
  isValidTelephoneNumber,
} from '../../utils/validation-utils';

const validationHelper = {
  getValidatorFunc: getValidatorFunc,
};

const TEXTAREA_MAX_LENGTH = 400;

function getValidatorFunc(namesOfFieldsOnForm) {
  return formValues => {
    let errors = {};

    if (!formValues.firstName) {
      errors.firstName = 'First name is required';
    } else if (formValues.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less';
    } else if (!isValidName(formValues.firstName)) {
      errors.firstName = 'Please enter a valid name';
    }

    if (!formValues.lastName) {
      errors.lastName = 'Last name is required';
    } else if (formValues.lastName.length > 20) {
      errors.lastName = 'Must be 20 characters or less';
    } else if (!isValidName(formValues.lastName)) {
      errors.lastName = 'Please enter a valid name';
    }

    if (!formValues.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(formValues.email)) {
      errors.email = 'Invalid email address';
    }

    if (!formValues.phoneNumber) {
      errors.phoneNumber = 'Required';
    } else if (!isValidTelephoneNumber(formValues.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number';
    }

    if (!formValues.message) {
      errors.message = 'Required';
    } else if (formValues.message.length > TEXTAREA_MAX_LENGTH) {
      errors.message = `Must be ${TEXTAREA_MAX_LENGTH} characters or less`;
    }

    return errors;
  };
}

export default validationHelper;
