import apiService from '../services/api-service';
import referrerDataService from './referrer-data-service';

const contactUsService = {
  sendStaffNotification: sendStaffNotification,
};

function sendStaffNotification(formData) {
  let path = '/api/staff/contact';

  let data = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    phoneNumber: formData.phoneNumber,
    email: formData.email,
    message: formData.message,
    referrerUrl: referrerDataService.getSavedReferrer(),
    utmParams: referrerDataService.getSavedUtmData(),
  };

  return apiService.post(path, data);
}

export default contactUsService;
